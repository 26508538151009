/* core styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  background-color: teal;
  height: 100%;
}

a {
  text-decoration: none;
  color: white;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  display: block;
  width: 100%;
  margin: 5px auto;
  padding: 0.25rem;
  background-color: white;
}

input[type='submit'] {
  display: block;
  margin: 5px auto;
  padding: 5px;
  color: white;
  background-color: teal;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

.btn {
  background-color: teal;
  color: rgba(255, 255, 255, 0.952);
  font-size: 15px;
  padding: 5px 5px;
  margin: 5px 5px;
  border-radius: 15px;
  opacity: 0.75;
  border-style: solid;
  border-width: 0.1em;
}

.btn:hover {
  opacity: 1;
  font-weight: bold;
  /* border-width: 0.2em; */
}

.deleteBtn {
  background-color: rgb(245, 65, 45);
  color: rgba(255, 255, 255, 0.952);
  font-size: 15px;
  padding: 2px 5px;
  margin: 5px 5px;
  border-radius: 15px;
}

.dark-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
  overflow: scroll;
}

.smallText {
  font-size: small;
}

.defaultHeader {
  color: rgba(255, 255, 255, 0.952);
  font-size: 45px;
  position: relative;
  animation: blink-header 1s linear infinite;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  background-color: transparent;
}

.alert {
  padding: 0.3rem;
  margin: 1rem 2rem;
  opacity: 0.9;
  background-color: crimson;
  color: rgb(26, 25, 25);
  font-size: 1.25rem;
  font-weight: bold;
}

/* navbar styles */
.navbar {
  text-align: right;
  margin: 5px 5px;
  padding: 5px 5px;
  background-color: rgba(0, 0, 0, 0.473);
}

.navbar > a {
  color: rgba(255, 255, 255, 0.952);
  margin: 10px 10px;
}

/* landing page styles */
.landing {
  position: relative;
  height: 100vh;
  text-align: center;
  background: url('../img/Girl-hand-laptop-working.jpeg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.landingHeader {
  color: rgba(255, 255, 255, 0.952);
  font-size: 45px;
  position: relative;
  animation: move-landing-header, blink-header 1s linear infinite;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  background-color: transparent;
}

@keyframes move-landing-header {
  0% {
    top: -10%;
  }
  100% {
    top: 10%;
  }
}

@keyframes blink-header {
  50% {
    opacity: 0;
  }
}

.landingLinks {
  position: relative;
  top: 12%;
  background-color: transparent;
  display: block;
}

.readMe {
  background-color: rgb(241, 241, 241);
  display: flex;
  flex-direction: column;
  margin: 0% 20%;
  padding: 1% 1%;
  border-radius: 20px;
  position: relative;
  display: block;
  opacity: 0.5;
  animation: move-readme;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes move-readme {
  0% {
    top: 110%;
  }
  100% {
    top: 20%;
  }
}

.readMe:hover {
  opacity: 1;
}

.readMe div.line {
  height: 1px;
  background: #ccc;
  margin: 0.5rem 0;
}

/* register and login page styles */
.authBox {
  background-color: rgb(241, 241, 241);
  padding: 2% 2%;
  margin: 2% 30%;
  border-radius: 20px;
  text-align: left;
}

.authPic {
  position: relative;
  animation: move-auth-pic;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  border-radius: 15px;
}

@keyframes move-auth-pic {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

/* dashboard styles */
.submissionBox {
  background-color: rgb(241, 241, 241);
  padding: 2% 2%;
  margin: 2% 30%;
  border-radius: 20px;
  text-align: left;
}

.subredditTableAndDeleteBtn {
  margin: 2% auto;
}

.subredditTable {
  background-color: rgb(241, 241, 241);
  display: inline-block;
  text-align: center;
  border-radius: 6px;
}

.subredditTable div.line {
  height: 1px;
  background: #ccc;
  margin: 0.1rem 0;
}

/* settings page styles */
.sectionBox {
  padding: 2% 2%;
  margin: 2% 30%;
  background-color: rgb(241, 241, 241);
  border-radius: 20px;
  text-align: left;
}

.phoneNumAndDeleteBtn {
  display: flex;
  margin: 5px 5px;
}

.phoneNumDeleteBtn {
  margin: 0px 10px;
}
